import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import Axios from '../config/axios';
import NumberFormat from 'react-number-format';
import ReactLoading from 'react-loading';

import { smallBox } from "../common/utils/functions";

class Contact extends Component {
    constructor(props) {
        super(props);

        this.state = {
            formFields: {
                contactEmail: "",
                firstName: "",
                lastName: "",
                contactPhone: "",
                contactContent: ""
            },
            loading: false,
            disabled: true,
            messageSuccess: false,
            messageFail: false
        }

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({ formFields: { ...this.state.formFields, [name]: value } });
    }

    handleSubmit() {
        let postdata = {};

        postdata.firstName = this.state.formFields.firstName;
        postdata.lastName = this.state.formFields.lastName;
        postdata.email = this.state.formFields.contactEmail;
        postdata.content = this.state.formFields.contactContent;
        postdata.phone = this.state.formFields.contactPhone;

        if (postdata.firstName === "" ||
            postdata.lastName === "" ||
            postdata.email === "" ||
            postdata.content === "") {
            smallBox({
                title: "Notice",
                content: "<i class='fa fa-clock-o'></i> <i>Please fill in all required fields.</i>",
                color: "#C46A69",
                iconSmall: "fa fa-times fa-2x fadeInRight animated",
                timeout: 4000
            });
        } else {
            this.setState({
                loading: true
            });


            Axios.post(`/api/GetInTouch`, postdata
            ).then(response => {
                if (response.data === true) {

                    let ffCopy = this.state.formFields;
                    ffCopy.contactContent = "";
                    ffCopy.contactEmail = "";
                    ffCopy.firstName = "";
                    ffCopy.lastName = "";
                    ffCopy.contactPhone = "";
                    this.setState({
                        formFields: ffCopy,
                        loading: false,
                        messageSuccess: true
                    });
                    smallBox({
                        title: "Notice",
                        content: "<i class='fa fa-clock-o'></i> <i>Thank you for reaching out! We will be in touch shortly.</i>",
                        color: "#659265",
                        iconSmall: "fa fa-check fa-2x fadeInRight animated",
                        timeout: 4000
                    });
                } else {
                    smallBox({
                        title: "Notice",
                        content: "<i class='fa fa-clock-o'></i> <i>Something went wrong, please send us an email directly.</i>",
                        color: "#C46A69",
                        iconSmall: "fa fa-times fa-2x fadeInRight animated",
                        timeout: 4000
                    });

                    this.setState({
                        loading: false,
                        messageFail: true
                    });
                }
            }).catch(error => {
                console.log("Issues")
                this.setState({
                    loading: false,
                    messageFail: true
                });
                console.log(error);
            });
        }


    }


    render() {
        return (
            <div className="marginTop25">

                <div className="row">
                    <div className="col col-lg-12 col-12">
                        <h1>
                            Contact Us
                            </h1>
                        <div className="form-group">
                            <div className="row">
                                <div className="col col-lg-6 col-12">
                                    <label className="form-label">First Name <span className="required">*</span></label>
                                    <input className="form-control" name="firstName" placeholder="Enter your first name" aria-label="Enter your first name" value={this.state.formFields.firstName} onChange={this.handleInputChange} />
                                </div>
                                <div className="col col-lg-6 col-12">
                                    <label className="form-label">Last Name <span className="required">*</span></label>
                                    <input className="form-control" name="lastName" placeholder="Enter your last name" aria-label="Enter your last name" value={this.state.formFields.lastName} onChange={this.handleInputChange} />
                                </div>
                                <div className="col col-lg-6 col-12">
                                    <label className="form-label">Email <span className="required">*</span></label>
                                    <input className="form-control" type="email" name="contactEmail" placeholder="Enter your email" aria-label="Enter your email" value={this.state.formFields.contactEmail} onChange={this.handleInputChange} />
                                </div>
                                <div className="col col-lg-6 col-12">
                                    <label className="form-label">Phone Number</label>
                                    <NumberFormat className="form-control" name="contactPhone" value={this.state.formFields.contactPhone} onChange={this.handleInputChange} format="(###) ###-####" placeholder="Enter your phone number" mask="_" />
                                </div>
                                <div className="col col-lg-12 col-12">
                                    <label className="form-label">Message <span className="required">*</span></label>
                                    <textarea rows="10" name="contactContent" className="form-control" placeholder="Type your message here..." value={this.state.formFields.contactContent} onChange={this.handleInputChange}></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                
                <div className="row">
                    <div className="col-sm-12">
                        <button onClick={this.handleSubmit} className="btn btn-submit"><i className="fa fa-magic"></i> Send Message</button>
                        {this.state.loading &&
                            <span><ReactLoading className='tableLoading' type={"spokes"} color={'#7acfd6'} height={30} width={30} /></span>
                        }

                    </div>
                </div>

            </div>
        );
    }
}

export default withRouter(Contact);
