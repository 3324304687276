import React, { Component } from 'react';
import img from '../img/logo.png';


export class Layout extends Component {
    render() {
        return (
            <div className="page-wrapper background">
                <div className="page-inner">
                    <div className="page-content-wrapper">
                        
                        <main id="js-page-content" role="main" className="page-content">
                            {this.props.children}
                        </main>

                        <footer className="page-footer">
                            <div className="page-footer-text">
                                &#169; {(new Date().getFullYear())} 2 Feet 4 Good
                            </div>
                            <div className="text-center d-flex justify-content-center">
                                <img src={img} width={250} height={132} className="align-self-center" />

                                <div id="amznCharityBannerInner"><a href="https://smile.amazon.com/ch/85-2548794" target="_blank">
                                    <div class="text" height="">
                                        <div class="support-wrapper">
                                            <div class="support" style={{ fontSize: '25px', lineHeight: '28px', marginTop: '29px', marginBottom: '29px' }}>
                                                Support <span id="charity-name" style={{ display: 'inline-block' }}>2 Feet 4 Good.</span>
                                            </div>
                                        </div>
                                        <p class="when-shop">When you shop at <b>smile.amazon.com,</b></p>
                                        <p class="donates">Amazon donates.</p>
                                    </div></a>
                                </div>
                            </div>
                            <div className="text-right">
                                <form action="https://www.paypal.com/donate" method="post" target="_blank" rel="noopener noreferrer">
                                    <input type="hidden" name="hosted_button_id" value="U95AG2JGW4XGC" />
                                    <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_donate_LG.gif" border="0" name="submit" title="Donate with PayPal" alt="Donate with PayPal button" />
                                    <img alt="" border="0" src="https://www.paypal.com/en_US/i/scr/pixel.gif" width="1" height="1" />
                                </form>
                            </div>
                        </footer>
                    </div>
                </div>
            </div>
        );
    }
}
