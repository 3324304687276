import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import Carousel from 'react-bootstrap/Carousel'
import 'font-awesome/css/font-awesome.min.css';

import eddie from '../img/eddie_test.jpg';
import samuel from '../img/samuel_story.jpg';

class Stories extends Component {

    render() {

        return (
            <div>
                <h1>
                    Stories
                    </h1>
                {/*<div>
                    <h2 className="text-center">Eddie</h2>
                    <div className="row">
                        <div className="col col-lg-8 col-12">
                            <p>
                                Eddie had wanted to be a Marine since he was 12 years old. His dad was a Marine. In his senior year of high school, Eddie knew he was destined to join the ranks of the few and the proud. Eddie had gotten more intense and focused after the attacks on Sept. 11, 2001, cementing his commitment to serving his country.

                                In June 2002, he graduated high school and immediately enlisted for a four-year hitch. In January of 2003 Eddie deployed for the first time, manning a machine gun in Iraq. He fought from the Kuwaiti border through Nasariya and up to Al Kut.

                                Eddie then served a second tour in Djibouti, Africa, eventually leading to going on his third deployment as a Marine Sniper in Iraq.

                                On April 13, 2005, on his third tour, Eddie was shot twice in his head, once in his lower jaw and once above his eye. Eddie was severely wounded and suffered a traumatic brain injury. Now, because prayer is going strong for our American hero, Eddie is recovering and doing well.

                                From the beginning, Sgt Ryan has been an inspiration to all that he meets. He continues to break all expectations and show that now matter what, a Marine will never back down. Eddie has a tattoo, “Land of the free, because of the brave,” and there is no one in the world that is a finer example than him.
                                    </p>
                        </div>
                        <div className="col-lg-4 col-12">
                            <table id="wrapper">
                                <tr>
                                    <td><img src={eddie} alt="" height="400" /></td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>*/}

                <Carousel
                    interval='5000'
                    pause='hover'
                    nextIcon={<i className="fa fa-chevron-right chevron-icon"></i>}
                    prevIcon={<i className="fa fa-chevron-left chevron-icon"></i>}
                >
                    <Carousel.Item>
                        <div>
                            <h2>Eddie</h2>
                            <div className="row">
                                <div className="col col-lg-8 col-12">
                                    <p>
                                        Eddie had wanted to be a Marine since he was 12 years old. His dad was a Marine. In his senior year of high school, Eddie knew he was destined to join the ranks of the few and the proud. Eddie had gotten more intense and focused after the attacks on Sept. 11, 2001, cementing his commitment to serving his country. 

                                        In June 2002, he graduated high school and immediately enlisted for a four-year hitch. In January of 2003 Eddie deployed for the first time, manning a machine gun in Iraq. He fought from the Kuwaiti border through Nasariya and up to Al Kut. 

                                        Eddie then served a second tour in Djibouti, Africa, eventually leading to going on his third deployment as a Marine Sniper in Iraq.      

                                        On April 13, 2005, on his third tour, Eddie was shot twice in his head, once in his lower jaw and once above his eye. Eddie was severely wounded and suffered a traumatic brain injury. Now, because prayer is going strong for our American hero, Eddie is recovering and doing well.  

                                        From the beginning, Sgt Ryan has been an inspiration to all that he meets. He continues to break all expectations and show that now matter what, a Marine will never back down. Eddie has a tattoo, “Land of the free, because of the brave,” and there is no one in the world that is a finer example than him. 
                                    </p> 
                                </div>
                                <div className="col-lg-4 col-12">
                                    <table id="wrapper">
                                        <tr>
                                            <td><img src={eddie} alt="" height="w-100" /></td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </Carousel.Item>
                    <Carousel.Item>
                        <div>
                            <h2>Samuel</h2>
                            <div className="row">
                                <div className="col col-lg-8 col-12">
                                    <p>
                                        Our son Samuel was born with a condition know as Osteogenesis Imperfecta or Brittle Bones. This condition is extremely rare and leads to easily fractured bones as well as many other issues that range from feeding to breathing to mobility. Despite this Samuel is a typical little boy who loves life and is bound a determined to do many of the things a typical boy loves to do.  
                                        Kevin and the Tougher Than a Tank crew graciously chose Samuel to be the recipient of their 2020 running fundraiser. We couldn’t have been more thrilled and are forever grateful! They have left a lasting impression on Samuel and our family. The thoughtfulness of the "crew" to pick a young boy and family who some of them had never even met before blew us away. 
                                        Thank you Kevin and Tougher Than a Tank we truly appreciate everything you do!
                                    </p>
                                </div>
                                <div className="col-lg-4 col-12">
                                    <table id="wrapper">
                                        <tr>
                                            <td><img src={samuel} alt="" className="w-100" /></td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>

                    </Carousel.Item>
                    
                </Carousel>
                
            </div>
        );
    }
}

export default withRouter(Stories);